import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

// eslint-disable-next-line
import { mainDomain, hostName } from "../host";
export var getCookie = function getCookie(utmKey) {
  var cookieString = decodeURIComponent(document.cookie).split('; ');

  for (var val = 0; val < cookieString.length; val++) {
    var item = cookieString[val];

    var _item$split = item.split('='),
        _item$split2 = _slicedToArray(_item$split, 2),
        queryKey = _item$split2[0],
        queryVal = _item$split2[1];

    if (utmKey === queryKey) {
      return queryVal;
    }
  }

  return '';
};
export var getCookieValue = function getCookieValue(name) {
  return getCookie(name);
};
export var setCookie = function setCookie(cookieStr, doExpires) {
  if (doExpires) {
    var date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = "expires=".concat(date.toUTCString());
    cookieStr.forEach(function (param) {
      document.cookie = "".concat(param, ";").concat(expires, ";domain=").concat(mainDomain, ";path=/");
    });
  } else {
    document.cookie = "".concat(cookieStr, ";domain=").concat(mainDomain, ";path=/");
  }
};
export var setCookieValue = function setCookieValue(params, doExpires) {
  return setCookie(params, doExpires);
};
export var hasValidCookie = function hasValidCookie() {
  var docCookies = getCookie().split(';');

  for (var cookie = 0; cookie < docCookies.length; cookie++) {
    if (docCookies[cookie].trim().split('=')[0] === 'utm_source') {
      return true;
    }
  }

  return false;
};
export var getValuesFromCookies = function getValuesFromCookies() {
  return getCookie().split('; ').filter(function (s) {
    return s !== '';
  }).join('&');
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(getCookie, "getCookie", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/cookie/index.js");
  reactHotLoader.register(getCookieValue, "getCookieValue", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/cookie/index.js");
  reactHotLoader.register(setCookie, "setCookie", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/cookie/index.js");
  reactHotLoader.register(setCookieValue, "setCookieValue", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/cookie/index.js");
  reactHotLoader.register(hasValidCookie, "hasValidCookie", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/cookie/index.js");
  reactHotLoader.register(getValuesFromCookies, "getValuesFromCookies", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/cookie/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();