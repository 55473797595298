(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

export var header = function header() {
  var siteNavHeaderElement = document.getElementsByClassName('js-site-nav-header')[0];
  var nav = document.getElementsByClassName('site-nav__bar-container')[0];
  var accessories = document.getElementsByClassName('accessories')[0];
  var onePiece = document.getElementsByClassName('one-piece')[0];
  var twoPiece = document.getElementsByClassName('two-piece')[0];
  var headerShopElements = document.getElementsByClassName('js-header-shop-cta');
  var hambergerMenu = document.getElementsByClassName('hamberger-menu')[0];
  var cartMenuIcon = document.getElementsByClassName('cart-menu')[0];
  var megaMenu = document.getElementsByClassName('js-mega-menu')[0];
  var body = document.getElementsByTagName('BODY')[0];
  var descEl = document.getElementsByClassName('js-shop-menu')[0];
  var shopMenu = document.getElementsByClassName('shop-menu-img')[0];
  var scrollPositon = 0;

  var stickyHeaderMenu = function stickyHeaderMenu() {
    if (megaMenu.classList.contains('show-mega-menu')) return;

    if (document.body.getBoundingClientRect().top > scrollPositon) {
      siteNavHeaderElement.classList.remove('sticky');
      siteNavHeaderElement.classList.add('fixed');
      siteNavHeaderElement.classList.remove('hide-navigation-menu-top');
    } else if (scrollPositon < 0) {
      siteNavHeaderElement.classList.add('sticky');
      siteNavHeaderElement.classList.add('hide-navigation-menu-top');
      siteNavHeaderElement.classList.remove('fixed');
    }

    scrollPositon = document.body.getBoundingClientRect().top;
  };

  var openMegaMenu = function openMegaMenu() {
    if (window.innerWidth < 768) {
      body.classList.add('disable-scroll');
      hambergerMenu.classList.add('overflow-hidden', 'opacity-0');
      cartMenuIcon.classList.add('overflow-hidden', 'opacity-0');
    }

    megaMenu.classList.add('show-mega-menu');
    megaMenu.classList.remove('hide-mega-menu');
  };

  var closeMegaMenu = function closeMegaMenu() {
    if (window.innerWidth < 768 && megaMenu.classList.contains('show-mega-menu')) {
      body.classList.remove('disable-scroll');
      descEl.classList.remove('show-shop-menu');
      hambergerMenu.classList.remove('overflow-hidden', 'opacity-0');
      cartMenuIcon.classList.remove('overflow-hidden', 'opacity-0');
    }

    megaMenu.classList.add('hide-mega-menu');
    megaMenu.classList.remove('show-mega-menu');
  };

  var windowSizeHome = function windowSizeHome() {
    if (window.innerWidth > 768) {
      megaMenu.classList.remove('show-mega-menu');
    }
  };
  /* eslint no-iterator: "error" */


  for (var index = 0; index < headerShopElements.length; index++) {
    headerShopElements[index].addEventListener('mouseenter', function () {
      nav.classList.add('is-hovered');
      openMegaMenu();
    });
    nav.addEventListener('mouseleave', function () {
      nav.classList.remove('is-hovered');
      closeMegaMenu();
    });
  }

  if (accessories) {
    accessories.addEventListener('mouseenter', function () {
      shopMenu.setAttribute('src', '/assets/images/landing-pages/home-1/header/accessories.jpg');
    });
    accessories.addEventListener('mouseleave', function () {
      shopMenu.setAttribute('src', '/assets/images/landing-pages/home-1/header/default.jpg');
    });
  }

  if (onePiece) {
    onePiece.addEventListener('mouseenter', function () {
      shopMenu.setAttribute('src', '/assets/images/landing-pages/home-1/header/one-piece.jpg');
    });
    onePiece.addEventListener('mouseleave', function () {
      shopMenu.setAttribute('src', '/assets/images/landing-pages/home-1/header/default.jpg');
    });
  }

  if (twoPiece) {
    twoPiece.addEventListener('mouseenter', function () {
      shopMenu.setAttribute('src', '/assets/images/landing-pages/home-1/header/two-piece.jpg');
    });
    twoPiece.addEventListener('mouseleave', function () {
      shopMenu.setAttribute('src', '/assets/images/landing-pages/home-1/header/default.jpg');
    });
  }

  window.addEventListener('resize', windowSizeHome, true);
  window.addEventListener('scroll', stickyHeaderMenu, false);
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(header, "header", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/variants/products/js/header.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();