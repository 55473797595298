(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var WWW = '';
var INDEX = 'index';
var variants = [WWW, INDEX];
var obj = {
  'swim-fit-quiz-1': 'swim-fit-quiz-1',
  'swim-fit-quiz-2': 'swim-fit-quiz-2',
  'swim-fit-quiz-3': 'swim-fit-quiz-3',
  'swim-fit-quiz-4': 'swim-fit-quiz-4',
  'swim-fit-quiz-5': 'swim-fit-quiz-5',
  'au/swim-fit-quiz-1': 'au-swim-fit-quiz-1',
  'au/swim-fit-quiz-2': 'au-swim-fit-quiz-2',
  'au/swim-fit-quiz-3': 'au-swim-fit-quiz-3',
  'au/swim-fit-quiz-4': 'au-swim-fit-quiz-4',
  'home-1': 'home-1',
  'home-2': 'home-2',
  'home-3': 'home-3',
  'home-4': 'home-4',
  'home-5': 'home-5',
  'home-6': 'home-6',
  'home-7': 'home-7',
  'home-8': 'home-8',
  'home-9': 'home-9',
  'home-10': 'home-10',
  'home-11': 'home-11',
  'home-12': 'home-12',
  'comparison-1': 'comparison-1',
  'bfcm-1': 'bfcm-1',
  'au/home-1': 'au-home-1',
  'au/home-2': 'au-home-2',
  'au/home-3': 'au-home-3',
  'au/home-4': 'au-home-4',
  'au/home-5': 'au-home-5',
  'au/home-6': 'au-home-6',
  'au/bfcm-1': 'au-bfcm-1',
  'au/comparison-1': 'au-comparison-1',
  'products/the-amalfi-1': 'the-amalfi-1',
  'au/products/the-amalfi-1': 'au-the-amalfi-1',
  'products/the-amalfi-2': 'the-amalfi-2',
  'au/products/the-amalfi-2': 'au-the-amalfi-2',
  'collections/all-suits-1': 'all-suits-1',
  'collections/one-pieces-1': 'one-pieces-1',
  'collections/best-sellers-1': 'best-sellers-1',
  'collections/best-sellers-2': 'best-sellers-2',
  'collections/best-sellers-3': 'best-sellers-3',
  'collections/spring-launch-1': 'spring-launch-1',
  'collections/sale-on-sale-1': 'sale-on-sale-1',
  'collections/new-arrivals-1': 'new-arrivals-1',
  'collections/endless-summer-1': 'endless-summer-1',
  'collections/white-suit-sale-1': 'white-suit-sale-1'
};
variants.forEach(function (k) {
  obj[k] = k;
});
export var utmVariantsMap = obj;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(WWW, "WWW", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/utm/variants.js");
  reactHotLoader.register(INDEX, "INDEX", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/utm/variants.js");
  reactHotLoader.register(variants, "variants", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/utm/variants.js");
  reactHotLoader.register(obj, "obj", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/utm/variants.js");
  reactHotLoader.register(utmVariantsMap, "utmVariantsMap", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/utm/variants.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();