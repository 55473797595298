(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import { VALID_PARAM } from "../tracker/constants";

var _default = function _default() {
  var customParamsObj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // ex. customParamsObj
  // { propName: 'value' }
  var validUtmParams = Object.values(VALID_PARAM);
  var additionalUtmParams = ['utm_variant', 'utm_lang', 'mixpanel_dist_id'];
  var utmParamsArr = validUtmParams.concat(additionalUtmParams);
  var utmParamsObj = JSON.parse(JSON.stringify(customParamsObj)); // private functions

  var getQueryVariable = function getQueryVariable(variable) {
    var pairValue;
    var query = document.cookie;
    var getQueryUtmParams = query.split('; ');
    getQueryUtmParams.forEach(function (getQueryUtmParam) {
      var pair = getQueryUtmParam.split('=');

      if (pair[0] === variable) {
        pairValue = pair[1];
      }
    });
    return pairValue;
  };

  utmParamsArr.forEach(function (item) {
    var key = item;
    var value = getQueryVariable(item);
    value && (utmParamsObj[key] = value);
  }); // Remove undefined values.

  Object.keys(utmParamsObj).forEach(function (keyItem) {
    return utmParamsObj[keyItem] === undefined && delete utmParamsObj[keyItem];
  });
  return utmParamsObj;
};

export default _default;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(_default, "default", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/utm/get-utm-params.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();