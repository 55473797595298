(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import { getCookie } from "../cookie";
import { A_UID } from "./constants";
var fbTrackingId = '407595692960923';
var googleAnalytics = window.location.pathname.indexOf('/au/') > -1 ? 'UA-85709274-3' : 'UA-85709274-1';
var gtagID = window.location.pathname.indexOf('/au/') > -1 ? 'GTM-MDC6MJJ' : 'GTM-PRMS9WV';
var snapchatPixelID = '';
var pinterestPixelID = '';
var twitterPixelID = '';
/* facebook pixel ----------- */

export var fireFacebook = function fireFacebook(event) {
  if (typeof fbq === 'undefined') {
    /* eslint-disable */
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return;

      n = f.fbq = function () {
        var _n;

        n.callMethod ? (_n = n).callMethod.apply(_n, arguments) : n.queue.push(arguments);
      };

      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'); // Code commented due to multiple fb pixel fire.

    fbq('init', fbTrackingId); // Insert your pixel ID here.

    fbq('track', 'PageView');
  }

  event && fbq('trackCustom', event); // fire custom tracker as per page

  /* eslint-enable */
};
/* facebook pixel ends */

/* googletagmanager pixel ends */

export var googleTagManager = function googleTagManager() {
  var firstScriptTag = document.getElementsByTagName('script')[0];
  var gaScriptTag = document.createElement('script');
  gaScriptTag.async = !0;
  gaScriptTag.src = "https://www.googletagmanager.com/gtag/js?id=".concat(gtagID);
  gaScriptTag.setAttribute('type', 'text/javascript');
  firstScriptTag.parentNode.insertBefore(gaScriptTag, firstScriptTag);
  gtag('js', new Date()); // gtag('config', 'ID') below

  gtag('config', gtagID);
};
/* googletagmanager pixel ends */

/* google pixel ----------*/

export var googlePixel = function googlePixel() {
  /* eslint-disable */
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * new Date();
    a = s.createElement(o), m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  /* eslint-enable */


  ga('create', googleAnalytics, 'auto');
};
export var fireGoogle = function fireGoogle(event) {
  var amptuidCookieValue = getCookie('amptuid');
  var GACustomDimesions = {
    LandingPage: 'dimension1',
    SubscribePage: 'dimension2',
    BlogPage: 'dimension3',
    trackerId: 'dimension4',
    auid: 'dimension5'
  }; // set dimension based on page type

  if (typeof event.LandingPage !== 'undefined' && event.LandingPage !== '') {
    var queryString = window.location.search.substr(1);
    var pairs = queryString ? queryString.split('&') : [];
    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    }); // set GA dimesions on basis of auid

    if (result[A_UID]) {
      ga('set', GACustomDimesions.auid, result[A_UID]);
    } // set GA dimesions on basis of amptuid


    if (amptuidCookieValue && amptuidCookieValue !== '') {
      ga('set', GACustomDimesions.trackerId, amptuidCookieValue);
    }

    ga('set', GACustomDimesions.LandingPage, event.LandingPage);
  } // set dimension based on page type


  if (typeof event.pageType !== 'undefined' && event.pageType !== '') {
    ga('set', GACustomDimesions[event.pageType], event.variant);
  } // gets called on page load


  if (event.variant) {
    ga('send', 'pageview'); // ga('send', 'event', {
    //   eventCategory: event.variant,
    //   eventAction: 'pageload',
    //   eventLabel: window.location.href,
    // });
  } //  gets called when on user click and sends custom events


  if (event.custom) {
    if (typeof event.trackEvent !== 'undefined' && event.trackEvent !== '') ga('send', 'event', {
      eventCategory: event.trackEvent,
      eventAction: event.trackAction || 'click',
      eventLabel: event.trackLabel
    });
  }
};
/* google pixel Ends */
// <!-- Snap Pixel Code -->

export var snapchatPixel = function snapchatPixel() {
  /* eslint-disable */
  (function (e, t, n) {
    if (e.snaptr) return;

    var a = e.snaptr = function () {
      a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
    };

    a.queue = [];
    var s = 'script',
        r = t.createElement(s);
    r.async = !0;
    r.src = n;
    var u = t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r, u);
  })(window, document, 'https://sc-static.net/scevent.min.js');

  snaptr('init', snapchatPixelID);
  snaptr('track', 'PAGE_VIEW');
}; // <!-- End Snap Pixel Code -->
// Capser Pinterest Pixel Code

export var pinterestPixel = function pinterestPixel(e) {
  if (!window.pintrk) {
    window.pintrk = function () {
      window.pintrk.queue.push(Array.prototype.slice.call(arguments));
    };

    var n = window.pintrk;
    n.queue = [], n.version = '3.0';
    var t = document.createElement('script');
    t.async = !0, t.src = 'https://s.pinimg.com/ct/core.js';
    var r = document.getElementsByTagName('script')[0];
    r.parentNode.insertBefore(t, r);
  }

  pintrk('load', pinterestPixelID);
  pintrk('track', 'Pageview');
}; // End Capser Pinterest Pixel Code

/* Twitter Pixel */

export var twitterPixel = function twitterPixel() {
  !function (e, t, n, s, u, a) {
    e.twq || (s = e.twq = function () {
      s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
    }, s.version = '1.1', s.queue = [], u = t.createElement(n), u.async = !0, u.src = '//static.ads-twitter.com/uwt.js', a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a));
  }(window, document, 'script'); // Insert Twitter Pixel ID and Standard Event data below

  twq('init', twitterPixelID);
  twq('track', 'PageView');
};
/* Twitter Pixel Ends */

export var platformPixels = function platformPixels() {};
/* custom Twitter  tracker */

export var customTwitter = function customTwitter() {
  // eslint-disable-next-line no-undef
  if (typeof twttr !== 'undefined' && twttr) {
    // eslint-disable-next-line no-undef
    twttr.conversion.trackPid(twitterPixelID, {
      tw_sale_amount: 0,
      tw_order_quantity: 0
    });
  }
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(fbTrackingId, "fbTrackingId", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(googleAnalytics, "googleAnalytics", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(gtagID, "gtagID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(snapchatPixelID, "snapchatPixelID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(pinterestPixelID, "pinterestPixelID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(twitterPixelID, "twitterPixelID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(fireFacebook, "fireFacebook", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(googleTagManager, "googleTagManager", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(googlePixel, "googlePixel", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(fireGoogle, "fireGoogle", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(snapchatPixel, "snapchatPixel", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(pinterestPixel, "pinterestPixel", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(twitterPixel, "twitterPixel", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(platformPixels, "platformPixels", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
  reactHotLoader.register(customTwitter, "customTwitter", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/platform-pixel.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();