import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var DEFAULT_DOMAIN = '';
var prodEnvs = [''];
var hostname = window.location.hostname;

var _hostname$split = hostname.split('.'),
    _hostname$split2 = _slicedToArray(_hostname$split, 4),
    subDomain = _hostname$split2[0],
    domainName = _hostname$split2[1],
    top = _hostname$split2[2],
    localization = _hostname$split2[3];

var locDomainName = localization || '';
export var isProd = prodEnvs.indexOf(subDomain) !== -1;
var LOCALHOST = hostname;
export var isLocalhost = hostname.indexOf('localhost') !== -1 || hostname.indexOf('0.0.0.0') !== -1;
export var parsedDomain = isLocalhost ? LOCALHOST : locDomainName ? "".concat(domainName, ".").concat(top || 'com', ".").concat(localization) : domainName ? "".concat(domainName, ".").concat(top || 'com') : DEFAULT_DOMAIN;
export var parsedSubDomain = isLocalhost ? '' : subDomain;
export var mainDomain = isLocalhost ? LOCALHOST : parsedDomain;
export var hostName = isLocalhost ? "http://".concat(LOCALHOST, ":").concat(window.location.port) : "https://".concat(parsedSubDomain, ".").concat(parsedDomain);
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(DEFAULT_DOMAIN, "DEFAULT_DOMAIN", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(prodEnvs, "prodEnvs", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(hostname, "hostname", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(subDomain, "subDomain", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(domainName, "domainName", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(top, "top", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(localization, "localization", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(locDomainName, "locDomainName", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(isProd, "isProd", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(LOCALHOST, "LOCALHOST", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(isLocalhost, "isLocalhost", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(parsedDomain, "parsedDomain", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(parsedSubDomain, "parsedSubDomain", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(mainDomain, "mainDomain", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
  reactHotLoader.register(hostName, "hostName", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/host/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();