(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

/* eslint-disable */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
var firebaseConfig = {
  apiKey: 'AIzaSyBYze13FlRBlio50xrWmxytK6UnWI6Vi20',
  authDomain: 'andie-pdp-us.firebaseapp.com',
  projectId: 'andie-pdp-us',
  storageBucket: 'andie-pdp-us.appspot.com',
  messagingSenderId: '437991944727',
  appId: '1:437991944727:web:ea35afc6e8e886e1a52252'
};
var firebaseConfigAu = {
  apiKey: 'AIzaSyDc5-WGl61dhggW_e1bZM4olbRmy_gf8vQ',
  authDomain: 'andie-au.firebaseapp.com',
  projectId: 'andie-au',
  storageBucket: 'andie-au.appspot.com',
  messagingSenderId: '248967068980',
  appId: '1:248967068980:web:97713ceadc1fe99e07f961'
};

var initFirebase = function initFirebase(country) {
  // eslint-disable-next-line no-unused-vars
  var config = country === 'US' ? firebaseConfig : firebaseConfigAu; // firebase.initializeApp(config);

  firebase.firestore();
  return firebase;
};

export var firebaseUS = firebase.initializeApp(firebaseConfig);
export var firebaseAU = firebase.initializeApp(firebaseConfigAu, 'firebase-AU');
var _default = initFirebase;
export default _default;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(firebaseConfig, "firebaseConfig", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/firebaseConfig/index.js");
  reactHotLoader.register(firebaseConfigAu, "firebaseConfigAu", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/firebaseConfig/index.js");
  reactHotLoader.register(initFirebase, "initFirebase", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/firebaseConfig/index.js");
  reactHotLoader.register(firebaseUS, "firebaseUS", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/firebaseConfig/index.js");
  reactHotLoader.register(firebaseAU, "firebaseAU", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/firebaseConfig/index.js");
  reactHotLoader.register(_default, "default", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/firebaseConfig/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();