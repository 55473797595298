(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

export var AMPTUID = 'amptuid';
export var FULL_STORY = 'fullstory_funnel';
export var UTM_SOURCE = 'utm_source';
export var UTM_CAMPAIGN = 'utm_campaign';
export var UTM_MEDIUM = 'utm_medium';
export var UTM_CONTENT = 'utm_content';
export var UTM_7FH285 = '7fh285_';
export var UTM_7FH285_DR = '7fh285_dr';
export var UTM_7FH285_AUID = '7fh285_auid';
export var UTM_MSA_SOURCE = 'utm_msa_source';
export var UTM_MSA = 'msa';
export var UTM_LANG = 'utm_lang';
export var UMT_PAYMENTS_EXP = 'utm_payments_exp';
export var UTM_FBF_EXP = 'utm_fbf_exp';
export var UTM_OW_EXP = 'utm_ow_exp';
export var UETSID = '_uetsid';
export var UTM_VAL = 'ampush';
export var UTM_VARIANT = 'utm_variant';
export var A_UID = '7fh285_auid'; // Valid URL UTM Parameters.

export var VALID_PARAM = {
  UTM_SOURCE: UTM_SOURCE,
  UTM_CAMPAIGN: UTM_CAMPAIGN,
  UTM_MEDIUM: UTM_MEDIUM,
  UTM_7FH285: UTM_7FH285,
  UTM_CONTENT: UTM_CONTENT,
  UTM_7FH285_DR: UTM_7FH285_DR,
  UTM_7FH285_AUID: UTM_7FH285_AUID,
  UTM_MSA_SOURCE: UTM_MSA_SOURCE,
  UTM_MSA: UTM_MSA,
  UMT_PAYMENTS_EXP: UMT_PAYMENTS_EXP,
  UTM_FBF_EXP: UTM_FBF_EXP,
  UTM_OW_EXP: UTM_OW_EXP
};
export var UTM_PARAMS = [UTM_SOURCE, UTM_CAMPAIGN, UTM_MEDIUM, UTM_VARIANT, 'utm_sd', 'utm_type', 'utm_aid', 'utm_user_id', 'utm_lang', 'utm_term'];
export var ParamDefaultOrder = [UTM_SOURCE, UTM_CAMPAIGN, UTM_MEDIUM, UTM_VARIANT, 'utm_sd', 'utm_type', 'utm_aid', 'utm_user_id', 'utm_lang', 'utm_term'];
export var ABANALYTICS = {
  fullstoryfs: 'ampush_pay_v5_fullstory_integration',
  apiURL: 'https://ampid.ampush.io',
  ExpPage: true,
  hcExpURL: 'https://experimenter.ampush.net/experimenter',
  initiative: ''
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(AMPTUID, "AMPTUID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(FULL_STORY, "FULL_STORY", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_SOURCE, "UTM_SOURCE", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_CAMPAIGN, "UTM_CAMPAIGN", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_MEDIUM, "UTM_MEDIUM", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_CONTENT, "UTM_CONTENT", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_7FH285, "UTM_7FH285", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_7FH285_DR, "UTM_7FH285_DR", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_7FH285_AUID, "UTM_7FH285_AUID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_MSA_SOURCE, "UTM_MSA_SOURCE", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_MSA, "UTM_MSA", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_LANG, "UTM_LANG", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UMT_PAYMENTS_EXP, "UMT_PAYMENTS_EXP", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_FBF_EXP, "UTM_FBF_EXP", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_OW_EXP, "UTM_OW_EXP", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UETSID, "UETSID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_VAL, "UTM_VAL", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_VARIANT, "UTM_VARIANT", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(A_UID, "A_UID", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(VALID_PARAM, "VALID_PARAM", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(UTM_PARAMS, "UTM_PARAMS", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(ParamDefaultOrder, "ParamDefaultOrder", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
  reactHotLoader.register(ABANALYTICS, "ABANALYTICS", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/constants.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();