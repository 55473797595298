(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import UtmApi from "../utm/api";
import { fireFacebook, fireGoogle } from "./platform-pixel";
export var customExtraParams = function customExtraParams() {
  var extraParams;

  if (!window.extraParams) {
    extraParams = {};
    window.extraParams = {};
  } else {
    extraParams = window.extraParams;
  }

  if (window.utm_exp) {
    extraParams.utm_exp = window.utm_exp;
  }

  return extraParams;
};
export var fireCustomTracker = function fireCustomTracker(track) {
  var dataTrackName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var dataTrackAction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var dataTrackLocation = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  console.info('event', track);
  var trackEvents = track.split(',');
  var customEvent = {
    custom: true
  };
  trackEvents.forEach(function (trackEvent) {
    customEvent.trackEvent = trackEvent;

    if (dataTrackAction) {
      customEvent.trackAction = dataTrackAction.toLowerCase().replace(/ /g, '-');
    }

    if (dataTrackLocation) {
      customEvent.trackLocation = dataTrackLocation.toLowerCase().replace(/ /g, '-');
    }

    customEvent.trackLabel = dataTrackName.replace(/ /g, '-');
    var extraParams = customExtraParams(); // form params

    switch (trackEvent) {
      case 'cta':
        extraParams = {};
        extraParams.utm_cta_id = customEvent.trackLabel;
        extraParams.utm_cta_location = customEvent.trackLocation;
        break;

      default: // do nothing

    }

    fireFacebook(trackEvent); // fire custom tracker as per page

    fireGoogle(customEvent);
    var utmParams = UtmApi.getUtmParams(UtmApi.filterParams(extraParams));
    atrack(trackEvent, utmParams);
  });
};
export function ampDataTrack(e, dat) {
  e.stopPropagation();
  var that = dat || this;
  var href = that.getAttribute('href');
  var dataTrack = that.getAttribute('data-track');
  var dataTrackName = that.getAttribute('data-track-name');
  var dataTrackAction = that.getAttribute('data-track-action');
  var dataTrackLocation = that.getAttribute('data-track-location');

  if (e.metaKey || e.ctrlKey) {
    fireCustomTracker(dataTrack, dataTrackName, dataTrackAction, dataTrackLocation);
  } else if (!href) {
    fireCustomTracker(dataTrack, dataTrackName, dataTrackAction, dataTrackLocation);
  } else {
    e.preventDefault();
    fireCustomTracker(dataTrack, dataTrackName, dataTrackAction, dataTrackLocation);
    href && UtmApi.hrefDelay(href, 800);
  }
} // utm variant logic relies on the folder structures on TRY and WWW

export function customTracker() {
  window.addEventListener('load', function () {
    var trackerEls = document.getElementsByClassName('js-track');
    var eventListener;

    if (trackerEls) {
      for (var index = 0; index < trackerEls.length; index++) {
        var trackerEl = trackerEls[index];

        switch (trackerEl.dataset.event) {
          case 'change':
            eventListener = 'change';
            break;

          default:
            eventListener = 'click';
        }

        trackerEl.addEventListener(eventListener, ampDataTrack, false);
      }
    }
  });
}
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(customExtraParams, "customExtraParams", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/custom-tracker.js");
  reactHotLoader.register(fireCustomTracker, "fireCustomTracker", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/custom-tracker.js");
  reactHotLoader.register(ampDataTrack, "ampDataTrack", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/custom-tracker.js");
  reactHotLoader.register(customTracker, "customTracker", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/custom-tracker.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();